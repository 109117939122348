<template>
  <div v-loading="loading">
    <div class="header padd15 x-f" v-if="!isDialog">
      <h4>素材中心</h4>
    </div>
    <div class="main">
      <el-tabs v-model="mediaType" @tab-click="handleClick">
        <el-tab-pane label="图片" name="1" v-if="[0, 1].includes(displayType)"> </el-tab-pane>
        <el-tab-pane label="视频" name="2" v-if="[0, 2].includes(displayType)"> </el-tab-pane>
        <el-tab-pane label="素材" name="3" v-if="[0, 1].includes(displayType)"> </el-tab-pane>
      </el-tabs>
      <div class="x-start marB10">
        <c-button v-if="mediaType == '1'||$store.state.user.userinfo.tenantId == 2" class="whiteColor" test="上传图片"
          icon="el-icon-plus" bgColor="#1890ff" @click="getUploadOpen"></c-button>
        <c-button v-if="mediaType == '2'" class="whiteColor" test="上传视频" icon="el-icon-plus" bgColor="#1890ff"
          @click="getUploadOpen"></c-button>
        <!-- <c-button
                    class="whiteColor"
                    test="同步类型"
                    icon="el-icon-refresh"
                    bgColor="#1890ff"
                    @click="getSynchronousType"
                ></c-button> -->
        <!-- <el-button
          type="text"
          v-if="isDialog"
          @click="$router.push('/material/list')"
          >前往素材中心
        </el-button> -->
      </div>

      <div>
        <div class="x-start box">
          <div class="lift-tree" v-loading="typeLoading">
            <div class="treeBox padd15">
              <div class="x-bc marB10">
                <span class="fontS14">素材分组</span>
                <div v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2">
                  <span class="fontS14B cursorP" v-show="isEdit" @click="clickEdit">编辑</span>
                  <span class="fontS14B cursorP" v-show="!isEdit" @click="clickEdit">完成编辑</span>
                </div>
              </div>
              <el-input placeholder="搜索分组名称" suffix-icon="el-icon-search" v-model="filterText" class="marB10">
              </el-input>
              <el-tree class="filter-tree" :data="groupTreeOptions" :props="defaultProps" default-expand-all
                highlight-current @node-click="handleNodeClick" :expand-on-click-node="false"
                :filter-node-method="filterNode" ref="tree">
                <span class="material-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span v-show="!isEdit">
                    <el-button v-if="node.label != '未分组'" icon="el-icon-circle-plus-outline" type="text" size="mini"
                      @click="() => appendTree(data)">
                    </el-button>
                    <el-button v-if="
                        node.label != '自定义分组' && node.label != '未分组'
                      " icon="el-icon-edit" type="text" size="mini" @click="() => editTree(node, data)">
                    </el-button>
                    <el-button v-if="
                        node.label != '自定义分组' && node.label != '未分组'
                      " icon="el-icon-delete" type="text" size="mini" @click="() => removeTree(node, data)">
                    </el-button>
                  </span>
                </span>
              </el-tree>
            </div>
          </div>
          <div class="right-list padd15">
            <span class="boxTitle">{{ groupingName }}</span>
            <div class="marT10 marB10 x-f">
              <div class="x-f">
                <div v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2||isDialog" class="x-x">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                  </el-checkbox>
                  <span class="marR10 marL10">|</span>
                  已选 {{ selectNum }} 项
                  <span class="marR10 marL10">|</span>
                </div>
                <c-button v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2" class="whiteColor"
                  test="修改分组" icon="el-icon-setting" :border="'#9f9f9f'" :color="'#6e6e6e'" style="height: 28px"
                  @click="batchUpdateGroup"></c-button>
                <c-button v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2" class="whiteColor"
                  test="删除" icon="el-icon-delete" :border="'#9f9f9f'" :color="'#6e6e6e'" style="height: 28px"
                  @click="batchDeleteImg"></c-button>
              </div>
            </div>
            <div class="x-w" v-loading="listLoading">
              <div class="y-start list marL10 marB10" v-for="(item, index) in tableData" :key="index">
                <div class="imgBox">
                  <div v-if="mediaType == 1||mediaType == 3" class="img" style="width: 100px; height: 100px">
                    <el-image class="elImg" :src="item.accessUrl" :preview-src-list="[item.accessUrl]"></el-image>
                  </div>
                  <video v-else style="width: 100px; height: 100px" :src="item.accessUrl"></video>
                </div>
                <div style="width: 100%" class="x-f">
                  <el-checkbox v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2||isDialog"
                    class="checkItem" v-model="item.selectCheck" @change="handleCheckChange(item)"></el-checkbox>
                  <el-tooltip :content="item.fileName" placement="top" effect="light">
                    <span class="marL10 omit" style="width: 80px">{{
                      item.fileName
                    }}</span>
                  </el-tooltip>
                </div>
                <div class="x-ac" style="width: 100%"
                  v-if="mediaType==1||mediaType==2||$store.state.user.userinfo.tenantId == 2">
                  <span type="text" @click="getEditImg(item)">编辑</span>
                  <span class="editBtn">|</span>
                  <span type="text" @click="getDeleteImg(item)">删除</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <bottomPage   v-model="queryParams"
  :getList="getList" :isSelectAll="false" :totalCom="total"/> -->
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize" @pagination="getList" />
      </div>
    </div>
    <!-- 自定义组 -->
    <el-dialog :title="groupTitle" :visible.sync="dialogGroupVisible" width="30%" v-dialogDrag="true"
      :append-to-body="true">
      <el-form :model="groupForm" ref="form" :rules="rules" v-loading="typeDiaLoading">
        <el-form-item label="所在分组" label-width="80px" prop="parentId">
          <treeselect v-model="groupForm.parentId" :options="groupTreeOptions" :show-count="true" placeholder="请选择所在分组"
            :normalizer="normalizer" @input="inputSelect" />
        </el-form-item>
        <el-form-item label="分组名称" label-width="80px" prop="typeName">
          <el-input v-model="groupForm.typeName" placeholder="20个字以内" autocomplete="off" class="inlineBlock"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="groupSubmit">确 定</el-button>
        <el-button @click="groupCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 上传图片 -->
    <el-dialog destroy-on-close title="上传图片" :visible.sync="uploadImgVisible" width="40%" v-dialogDrag="true"
      :append-to-body="true">
      <el-form :model="uploadForm" ref="uploadForm" :rules="uploadRules" v-loading="uploadDiaLoading">
        <el-form-item label="所在分组" label-width="80px" prop="materialTypeId">
          <treeselect style="width: 50%" v-model="uploadForm.materialTypeId" :options="groupTreeOptions"
            :show-count="true" placeholder="请选择所在分组" :normalizer="normalizer" @input="inputSelectUpload" />
        </el-form-item>
        <el-form-item label="* 本地图片" label-width="80px">
          <accessory-upload listType="picture-card" :isLimitMany="true" :limit="100000000" title=""
            @getFileItems="getFileItemsData" @delFileItems="delFileItems" :fileSize="imgFileSize"></accessory-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
        <el-button @click="uploadCancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 上传视频 -->
    <el-dialog destroy-on-close :title="(mediaType == 1||mediaType == 3) ? '上传图片' : '上传视频'"
      :visible.sync="uploadVideoVisible" width="40%" v-dialogDrag="true" :append-to-body="true">
      <el-form :model="uploadForm" ref="uploadForm" :rules="uploadRules" v-loading="uploadDiaLoading">
        <el-form-item label="所在分组" label-width="80px" prop="materialTypeId">
          <treeselect style="width: 50%" v-model="uploadForm.materialTypeId" :options="groupTreeOptions"
            :show-count="true" placeholder="请选择所在分组" :normalizer="normalizer" @input="inputSelectUpload" />
        </el-form-item>
        <el-form-item label="* 本地视频" label-width="80px">
          <!-- <upload-video @getFileItems="getFileItemsData"></upload-video> -->
          <OssUpload v-model="video.fileUrl" @change="getFileItemsData($event,2)" :option="{
                        listType: 'video',
                        type: 'mp4',
                        showList: false,
                        clear:true,
                        size: '20mb'
                      }" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
        <el-button @click="uploadCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 图片信息 -->
    <el-dialog :title="(mediaType == 1||mediaType == 3) ? '编辑图片' : '编辑视频'" :visible.sync="dialogEditImgVisible"
      width="30%" v-dialogDrag="true" :append-to-body="true">
      <el-form :model="editImgForm" ref="editImgForm" :rules="editImgRules" v-loading="editImgDiaLoading">
        <el-form-item label="所在分组" label-width="80px" prop="materialTypeId">
          <treeselect v-model="editImgForm.materialTypeId" :options="groupTreeOptions" :show-count="true"
            placeholder="请选择所在分组" :normalizer="normalizer" />
        </el-form-item>
        <el-form-item :label="(mediaType == 1||mediaType == 3) ? '图片标题' : '视频标题'" label-width="80px" prop="fileName">
          <el-input v-model="editImgForm.fileName" placeholder="50个字以内" autocomplete="off"
            class="inlineBlock"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editImgSubmit">确 定</el-button>
        <el-button @click="editImgCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 批量修改素材的分组 -->
    <el-dialog title="修改分组" :visible.sync="batchUpdateVisible" width="30%" v-dialogDrag="true" :append-to-body="true">
      <el-form :model="batchUpdateForm" ref="batchUpdateForm" :rules="batchUpdateFormRules"
        v-loading="batchUpdateDiaLoading">
        <el-form-item label="所在分组" label-width="80px" prop="materialTypeId">
          <treeselect v-model="batchUpdateForm.materialTypeId" :options="groupTreeOptions" :show-count="true"
            placeholder="请选择所在分组" :normalizer="normalizer" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchUpdateSubmit">确 定</el-button>
        <el-button @click="batchUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script> 
import OssUpload from '@/components/oss-upload/index.vue'
import bottomPage from '@/views/components/bottomPage' //底部分页
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CButton from '@/views/components/c-button/index.vue'
import {
  materialListAPI,
  materialAddAPI,
  materialDetailAPI,
  materialUpdateAPI,
  materialRemoveAPI,
  materialBatchUpdateAPI
} from '@/api/O2OMall/material/list' //素材库表
import {
  materialTypeListTreeAPI,
  materialTypeAddAPI,
  materialTypeDetailAPI,
  materialTypeUpdateAPI,
  materialTypeRemoveAPI,
  materialTypeSynchronizeAPI
} from '@/api/O2OMall/material/type' //素材库类型
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import UploadVideo from '@/views/components/uploadVideo/index.vue'

export default {
  name: 'material',
  props: {
    //弹窗
    isDialog: {
      type: Boolean,
      default: false
    },
    //是否单选
    isRadio: {
      type: Boolean,
      default: false
    },
    //图片大小限制，默认为1M
    imgFileSize: {
      type: Number,
      default: 1
    },
    //素材类型 0全部,1图片，2视频，3图文
    displayType: {
      type: Number,
      default: 0
    }
  },
  components: {
    AccessoryUpload,
    CButton,
    Treeselect,
    bottomPage,
    UploadVideo,
    OssUpload
  },
  data() {
    return {
      video: {}, //视频信息
      total: 0, // 总条数
      accessUrlList: [], //选中的数组url
      batchUpdateForm: {}, //批量修改素材分组对话框的表单
      batchUpdateVisible: false, //批量修改分组
      editImgForm: {}, //编辑图片对话框的表单
      dialogEditImgVisible: false, //编辑图片对话框
      uploadForm: {
        materialItems: []
      }, //上传图片
      uploadVideoForm: {
        materialItems: []
      }, //上传视频
      uploadImgVisible: false, //上传图片弹窗
      uploadVideoVisible: false, //上传视频弹窗
      isEdit: true, //编辑or完成编辑
      groupTitle: '分组信息', //素材弹窗标题
      groupForm: {}, //素材组
      dialogGroupVisible: false, //自定义组弹窗
      groupingName: '未分组', // 右边窗口编辑的分组名
      // 列表遮罩层
      listLoading: false,
      // 分组遮罩层
      typeLoading: false,
      // 分组弹窗遮罩层
      typeDiaLoading: false,
      // 上传图片遮罩层
      uploadDiaLoading: false,
      // 图片信息弹窗遮罩层
      editImgDiaLoading: false,
      // 批量修改分组遮罩层
      batchUpdateDiaLoading: false,
      // 遮罩层
      loading: false,
      isIndeterminate: false,
      checkAll: false, // 是否全选
      selectNum: 0, // 列表已选择数量
      mediaType: '1',
      filterText: '',
      tableData: [], //表格数据
      groupTreeOptions: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      queryParams: {
        pageNum: 1,
        pageSize: 50
      }, //查询
      //组别校验
      rules: {
        parentId: [
          {
            required: true,
            message: '所在分组不能为空',
            trigger: ['blur', 'change']
          }
        ],
        typeName: [
          {
            required: true,
            message: '请输入20个字以内的分组名',
            trigger: ['blur', 'change']
          }
        ]
      },
      //上传校验
      uploadRules: {
        materialTypeId: [
          {
            required: true,
            message: '所在分组不能为空',
            trigger: ['blur', 'change']
          }
        ],
        typeName: [
          {
            required: true,
            message: '请输入20个字以内的分组名',
            trigger: ['blur', 'change']
          }
        ]
      },
      //修改素材的内容校验
      editImgRules: {
        materialTypeId: [
          {
            required: true,
            message: '所在分组不能为空',
            trigger: ['blur', 'change']
          }
        ],
        fileName: [
          {
            required: true,
            message: '请输入50个字以内的图片标题',
            trigger: ['blur', 'change']
          }
        ]
      },
      //批量修改素材的分组校验
      batchUpdateFormRules: {
        materialTypeId: [
          {
            required: true,
            message: '所在分组不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created() {
    /* 初始化页面数据 */
    this.getInitializeData(this.queryParams)
  },
  watch: {
    displayType: {
      handler(val) {
        let mediaType = [2].includes(val) ? '2' : '1'
        if (this.mediaType != mediaType) {
          console.log('mediaType1', this.mediaType, mediaType);
          this.$nextTick(() => {
            this.mediaType = mediaType
          })
          this.$forceUpdate()
          console.log('mediaType2', this.mediaType);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //批量删除图片列表
    batchDeleteImg() {
      let arrId = []
      this.tableData.forEach(item => {
        if (item.selectCheck) {
          arrId.push(item.materialId)
        }
      })
      if (arrId.length <= 0) {
        return this.$message({
          message: '所选图片不能为空！',
          type: 'warning'
        })
      }
      this.$confirm(
        `确认删除所选${(this.mediaType == 1 || this.mediaType == 3) ? '图片' : '视频'}吗,是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }
      ).then(async () => {
        await materialRemoveAPI(arrId)
        this.$modal.msgSuccess('删除成功')
        this.isIndeterminate = false
        //刷新树状
        await this.getList()
      })
    },
    //批量修改分组弹窗确定
    async batchUpdateSubmit() {
      this.$refs['batchUpdateForm'].validate(async valid => {
        if (valid) {
          this.batchUpdateDiaLoading = true
          let arrId = []
          this.tableData.forEach(item => {
            if (item.selectCheck) {
              arrId.push(item.materialId)
            }
          })
          this.batchUpdateForm.materialIds = arrId
          await materialBatchUpdateAPI(this.batchUpdateForm)
          this.$modal.msgSuccess('批量修改成功')
          //关闭弹框
          this.batchUpdateCancel()
          this.isIndeterminate = false
          //刷新树状
          await this.getList()
          this.batchUpdateDiaLoading = false
        }
      })
    },
    //批量修改分组弹窗取消
    batchUpdateCancel() {
      this.batchUpdateReset()
      this.batchUpdateVisible = false
    },
    //批量修改分组按钮
    batchUpdateGroup() {
      this.batchUpdateReset()
      this.batchUpdateVisible = true
    },
    // 单选按钮
    handleCheckChange(value) {
      if (this.isRadio) {
        this.tableData.forEach(v => {
          if (v.materialId != value.materialId) {
            this.$set(v, 'selectCheck', false)
          }
        })
      }
      let isCheckAll = this.tableData.every(item => item.selectCheck)
      this.checkAll = isCheckAll
      let isCheck = this.tableData.some(item => item.selectCheck)
      if (isCheck && !isCheckAll) {
        this.isIndeterminate = true
      } else {
        this.isIndeterminate = false
      }
      let list = this.tableData.filter(item => item.selectCheck)
      this.accessUrlList = list.map(item => item.accessUrl).filter(ite => typeof ite !== 'undefined')
      this.selectNum = this.accessUrlList.length
      this.$emit('accessUrlListCom', this.accessUrlList, list)
    },
    // 全选按钮状态改变
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      this.tableData.forEach(item => (item.selectCheck = val))
      this.accessUrlList = this.tableData
        .map(item => {
          if (item.selectCheck) {
            return item.accessUrl
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.selectNum = this.accessUrlList.length
      this.$emit('accessUrlListCom', this.accessUrlList)
    },
    //删除图片
    async getDeleteImg(value) {
      this.$confirm(`确认删除所选图片吗,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        await materialRemoveAPI([value.materialId])
        this.$modal.msgSuccess('删除成功')
        //刷新树状
        await this.getList()
      })
    },
    //编辑图片弹窗确定
    async editImgSubmit() {
      this.$refs['editImgForm'].validate(async valid => {
        if (valid) {
          this.editImgDiaLoading = true
          await materialUpdateAPI(this.editImgForm)
          this.$modal.msgSuccess('修改成功')
          //关闭弹框
          this.editImgCancel()
          //刷新树状
          await this.getList()
          this.editImgDiaLoading = false
        }
      })
    },
    //编辑图片弹窗取消
    editImgCancel() {
      this.editImgFormReset()
      this.dialogEditImgVisible = false
    },
    //编辑图片
    async getEditImg(value) {
      this.groupReset()
      const res = await materialDetailAPI(value.materialId)
      this.editImgForm = res.data
      this.dialogEditImgVisible = true
    },
    // 给商户同步默认素材库类型
    async getSynchronousType() {
      if (this.mediaType == '1' || this.$store.state.user.userinfo.tenantId == 2) {
        await materialTypeSynchronizeAPI({
          mediaType: this.mediaType
        })
        this.$modal.msgSuccess('同步素材库类型成功')
      }
    },
    //打开上传图片弹窗
    getUploadOpen() {
      if (this.mediaType == 1 || this.mediaType == 3) {
        this.uploadReset()
        this.$set(this.uploadForm, 'materialTypeId', this.queryParams.materialTypeId)
        this.uploadImgVisible = true
      } else {
        this.uploadVideoReset()
        this.video = {}
        this.$set(this.uploadForm, 'materialTypeId', this.queryParams.materialTypeId)
        this.uploadVideoVisible = true
      }
    },
    //上传图片弹窗取消
    uploadCancel() {
      this.uploadReset()
      this.uploadImgVisible = false
      this.uploadVideoVisible = false
    },
    //上传图片弹窗确定
    async uploadSubmit() {
      if (this.uploadForm.materialItems.length <= 0) {
        this.$message({
          message: '上传图片不能为空',
          type: 'warning'
        })
        return
      }
      this.$refs['uploadForm'].validate(async valid => {
        if (valid) {
          this.uploadDiaLoading = true
          await materialAddAPI(this.uploadForm)
          this.$modal.msgSuccess('上传成功')
          //关闭弹框
          this.uploadCancel()
          //刷新树状
          await this.getList()
          this.uploadDiaLoading = false
          this.uploadVideoVisible = false
        }
      })
    },
    //上传阿里图库
    getFileItemsData(value, type = 1) {
      if (type == 2) {
        this.video = value
        this.uploadForm.materialItems = [{ accessUrl: value.httpsUrl, fileName: value.fileName, format: value.fileType, fileSize: value.fileSize, fileOldName: value.fileOldName }]
        this.uploadForm.materialItems.forEach(item => {
          item.mediaType = this.mediaType
        })
      } else {
        this.uploadForm.materialItems.push(value)
        this.uploadForm.materialItems.forEach(item => {
          item.mediaType = this.mediaType
        })
      }
      console.log('===', value, type);
    },
    //删除阿里图库
    delFileItems(value) {
      this.uploadForm.materialItems = this.uploadForm.materialItems.filter(
        item => item.filePath != value
      )
    },
    //节点点击
    async handleNodeClick(data) {
      this.groupingName = data.label
      this.queryParams.materialTypeId = data.id
      await this.getList()
    },
    // 组别弹窗确定
    groupSubmit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.groupForm.mediaType = this.mediaType
          if (this.groupForm.materialTypeId != undefined) {
            this.typeLoading = true
            await materialTypeUpdateAPI(this.groupForm)
            this.$modal.msgSuccess('修改成功')
            //关闭弹框
            this.groupCancel()
            //刷新树状
            await this.getListTree()
            this.typeLoading = false
          } else {
            this.typeLoading = true
            await materialTypeAddAPI(this.groupForm)
            this.$modal.msgSuccess('添加成功')
            //关闭弹框
            this.groupCancel()
            //刷新树状
            await this.getListTree()
            this.typeLoading = false
          }
        }
      })
    },
    // 关闭分组弹窗
    groupCancel() {
      this.groupReset()
      this.dialogGroupVisible = false
    },
    // 重置批量修改
    batchUpdateReset() {
      this.batchUpdateForm = {}
      this.resetForm('batchUpdateForm')
    },
    // 重置编辑图片
    editImgFormReset() {
      this.editImgForm = {}
      this.resetForm('editImgForm')
    },
    // 重置上传图片
    uploadReset() {
      this.uploadForm = { materialItems: [] }
      this.resetForm('uploadForm')
    },
    // 重置上传视频
    uploadVideoReset() {
      this.uploadVideoForm = { materialItems: [] }
      this.resetForm('uploadForm')
    },
    // 重置组别
    groupReset() {
      this.groupForm = {}
      this.resetForm('form')
    },
    //新增Tree用户组
    appendTree(data) {
      this.groupReset()
      this.groupTitle = '添加分组' //标题
      this.groupForm.parentId = data.id //所在组别
      this.dialogGroupVisible = true //打开弹窗
    },
    //编辑Tree用户组
    async editTree(node, data) {
      this.groupReset()
      this.typeDiaLoading = true //遮罩
      this.groupTitle = '编辑分组' //标题
      this.dialogGroupVisible = true //打开弹窗
      //获取用户组明细
      const res = await materialTypeDetailAPI(data.id)
      this.groupForm = res.data
      this.typeDiaLoading = false //遮罩
    },
    //删除Tree用户组
    removeTree(node, data) {
      this.$confirm(`确定删除(${node.data.label})分组吗,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        this.typeLoading = true
        await materialTypeRemoveAPI([data.id])
        this.$modal.msgSuccess('删除成功')
        //刷新树状
        await this.getListTree()
        this.typeLoading = false
      })
    },
    // 点击编辑树的按钮
    clickEdit() {
      this.isEdit = !this.isEdit
    },

    // 下拉框校验方法   (上传图片)
    inputSelectUpload() {
      this.$refs.uploadForm.validateField('materialTypeId')
    },
    // 下拉框校验方法   (分组)
    inputSelect() {
      this.$refs.form.validateField('parentId')
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    /* 列表 */
    async getList() {
      this.listLoading = true
      const res = await materialListAPI({
        ...this.queryParams,
        mediaType: this.mediaType
      })
      res.rows.forEach(item => {
        item.selectCheck = false
      })
      this.tableData = res.rows
      this.total = res.total
      this.listLoading = false
    },
    /*  树数据 */
    async getListTree() {
      const res = await materialTypeListTreeAPI({
        mediaType: this.mediaType
      })
      this.groupTreeOptions = res.data
    },
    /* 初始化页面数据 */
    async getInitializeData() {
      this.loading = true
      /* 列表 */
      await this.getList()
      /*  树数据 */
      await this.getListTree()
      this.loading = false
    },
    // 切换图片和视频
    async handleClick(val) {
      console.log('切换图片和视频', val.index)
      await this.getList()
      await this.getListTree()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.header,
.main {
  background: #ffffff;
  padding: 0 20px 20px 20px;
}

.box {
  height: 700px;
}

.lift-tree {
  width: 25%;
  position: relative;
  border: 1px solid #ababab;
  //素材组tree操作按钮区域样式
  .material-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;

    .el-button {
      color: #666666;
    }
  }

  .groupingBtn {
    border-top: 1px solid #ababab;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

.right-list {
  width: 75%;
  border-top: 1px solid #ababab;
  border-right: 1px solid #ababab;
  border-bottom: 1px solid #ababab;
  overflow-y: auto;

  .boxTitle {
    font-size: 17px;
    font-weight: bold;
  }

  .listBox {
  }
  .list {
    width: 120px;

    .imgBox {
      width: 100px;
      height: 100px;
      background: #f6f7f9;
      .img {
        .elImg {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep .el-image__inner {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }

    .checkItem {
      margin: 5px 0;
      // .checkItemText {
      //   overflow: hidden;
      //   word-break: break-all;
      //   word-wrap: break-word;
      //   text-overflow: ellipsis;
      // }
    }

    .editBtn {
      color: #ababab;
    }

    span {
      color: #1c75f4;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>
